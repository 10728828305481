import Joi from 'joi'

export interface ValidationError {
  [key: string]: string
}

export const homeSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.email': 'Invalid email format',
      'string.empty': 'Email is required',
      'any.required': 'Email is required',
    }),
  phone: Joi.string().pattern(new RegExp('^[+]?[0-9]{9,12}$')).required().messages({
    'string.pattern.base': 'Invalid phone format',
    'string.empty': 'Phone is required',
    'any.required': 'Phone is required',
  }),
  fullName: Joi.string().required().messages({
    'string.empty': 'Full name is required',
    'any.required': 'Full name is required',
  }),
}).unknown()

export const businessSchema = Joi.object({
  fullName: Joi.string().required().messages({
    'string.empty': 'Full Name is required',
    'any.required': 'Full Name is required',
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.email': 'Invalid email format',
      'string.empty': 'Email is required',
      'any.required': 'Email is required',
    }),
  phone: Joi.string().pattern(new RegExp('^[+]?[0-9]{9,12}$')).required().messages({
    'string.pattern.base': 'Invalid phone format',
    'string.empty': 'Phone is required',
    'any.required': 'Phone is required',
  }),
  businessName: Joi.string().required().messages({
    'string.empty': 'Business Name is required',
    'any.required': 'Business Name is required',
  }),
  businessAddress: Joi.string().required().messages({
    'string.empty': 'Business Address is required',
    'any.required': 'Business Address is required',
  }),
}).unknown()
