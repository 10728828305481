import { Outlet } from 'react-router-dom'
import { Suspense } from 'react'
import { Header } from './Header.tsx'
import styled from 'styled-components'
import { ErrorBoundary } from '@sentry/react'
import ErrorView from '@/uiComponents/ErrorView.tsx'
import { ResponsiveLayout } from '@/assets/style/mixins.ts'

const Main = styled.main`
  width: 100%;
  min-height: calc(100vh - var(--header-height));
  min-height: calc(100dvh - var(--header-height));
  padding-top: 110px;
  padding-bottom: 40px;
  ${ResponsiveLayout.queries};
`
export function Layout() {
  return (
    <>
      <Header />
      <Main>
        <ErrorBoundary fallback={<ErrorView />}>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </Main>
    </>
  )
}
