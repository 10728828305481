import { useState } from 'react'
import Minus from '@icons/minus.svg?react'
import Plus from '@icons/plus.svg?react'

import styled from 'styled-components'

const CounterContainer = styled.div`
  display: flex;
  width: 145px;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  border-radius: var(--radius-full);
  background-color: var(--gray-dark-mode-25);
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-semi-bold);
`
const CounterButton = styled.button<{ $position: 'left' | 'right' }>`
  display: flex;
  width: 40px;
  height: 35px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--gray-light-mode-100);
  color: var(--gray-light-mode-900);
  cursor: pointer;
  font-weight: var(--font-weight-bold);

  ${({ $position }) =>
    $position === 'left'
      ? 'border-top-left-radius:var(--radius-full); border-bottom-left-radius:var(--radius-full);'
      : 'border-top-right-radius:var(--radius-full); border-bottom-right-radius:var(--radius-full);'}
`

const CounterResult = styled.span`
  color: var(--gray-light-mode-900);
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-semi-bold);
  text-align: center;
`

type CounterProps = {
  initialValue?: number
  onCountChange: (count: number) => void
}

const Counter = ({ initialValue, onCountChange }: CounterProps) => {
  const [counter, setCounter] = useState<number>(!initialValue ? 0 : initialValue)

  const onHandleAdd = () => {
    setCounter((prev) => prev + 1)
    onCountChange(counter + 1)
  }

  const onHandleSubtract = () => {
    if (counter > 0) {
      setCounter((prev) => prev - 1)
      onCountChange(counter - 1)
    }
  }

  return (
    <CounterContainer>
      <CounterButton type="button" $position="left" onClick={onHandleSubtract}>
        <Minus />
      </CounterButton>
      <CounterResult>{counter}</CounterResult>
      <CounterButton type="button" $position="right" onClick={onHandleAdd}>
        <Plus />
      </CounterButton>
    </CounterContainer>
  )
}

export default Counter
