import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function PostLogoutPage() {
  const navigate = useNavigate()

  useEffect(() => {
    const path = sessionStorage.getItem('auth.logout.returnToPath')
    if (path === null) {
      return
    }
    sessionStorage.removeItem('auth.logout.returnToPath')
    navigate(path)
  }, [navigate])

  return <></>
}
