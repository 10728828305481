import { useState, ReactNode, useCallback, useMemo } from 'react'
import { getProfileData } from '@/profile/profileRequestsApi'
import { Profile } from '@/profile/profile'
import { ProfileContext } from './ProfileContext'

type ProfileProviderProps = {
  children: ReactNode
}

export const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const [profile, setProfile] = useState<Profile>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [token, setToken] = useState<string>('')

  const refreshProfile = useCallback(async () => {
    const savedThreadId = localStorage.getItem('thread_id')
    if (!savedThreadId) {
      return
    }
    try {
      setIsLoading(true)
      const { data } = await getProfileData(savedThreadId)
      if (data) {
        setProfile(data)
      }
    } catch (error) {
      console.error('Failed to refresh user data:', error)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const providerObject = useMemo(
    () => ({
      profile,
      isLoading,
      refreshProfile,
      setToken,
      token,
    }),
    [isLoading, profile, refreshProfile, token]
  )

  return <ProfileContext.Provider value={providerObject}>{children}</ProfileContext.Provider>
}
