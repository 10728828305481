import { useState, ReactNode, useMemo } from 'react'
import { OptimizedPackageContext } from './OptimizedPackageContext'

export const OptimizedPackageProvider = ({ children }: { children: ReactNode }) => {
  const [packageLoading, setPackageLoading] = useState(false)
  const [learnMoreQuestions, setLearnMoreQuestions] = useState<string[] | undefined>()

  const providerObject = useMemo(
    () => ({
      packageLoading,
      setPackageLoading,
      learnMoreQuestions,
      setLearnMoreQuestions,
    }),
    [learnMoreQuestions, packageLoading]
  )
  return <OptimizedPackageContext.Provider value={providerObject}>{children}</OptimizedPackageContext.Provider>
}
