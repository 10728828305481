import { useState, ReactNode, useMemo } from 'react'
import { InstallationRequestContext } from './InstallationRequestContext'

export const InstallationRequestProvider = ({ children }: { children: ReactNode }) => {
  const [proceedWithInstallationRequest, setProceedWithInstallationRequest] = useState<boolean>(false)

  const providerObject = useMemo(
    () => ({
      proceedWithInstallationRequest,
      setProceedWithInstallationRequest,
    }),
    [proceedWithInstallationRequest]
  )
  return <InstallationRequestContext.Provider value={providerObject}>{children}</InstallationRequestContext.Provider>
}
