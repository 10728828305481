import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { LoginControl } from '@/auth/LoginControl'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
`

const NavigationList = styled.ul`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 12px;
`

const StyledNavLink = styled(NavLink)`
  padding: 8px 16px;
  border-radius: var(--radius-md);
  color: var(--primary-link-color);

  &.active {
    background-color: var(--nav-link-active-background-color);
    color: var(--primary-text-color);
  }
`
const StyledLink = styled.a`
  padding: 8px 16px;
  border-radius: var(--radius-md);
  color: var(--primary-link-color);

  &.active {
    background-color: var(--nav-link-active-background-color);
    color: var(--primary-text-color);
  }
`

const NavEl = styled.nav`
  display: flex;
  align-items: center;
  gap: 40px;
`

type TProps = {
  navLinksData: {
    path: string
    label: string
  }[]
}

export function DesktopMenu(props: Readonly<TProps>) {
  const { navLinksData } = props
  const navLinks = navLinksData.map((link) => (
    <li key={link.path}>
      {link.label === 'Contact' ? (
        <StyledLink href={link.path} target="_blank" rel="noopener noreferrer">
          {link.label}
        </StyledLink>
      ) : (
        <StyledNavLink to={link.path}>{link.label}</StyledNavLink>
      )}
    </li>
  ))
  return (
    <Wrapper>
      <NavEl>
        <NavigationList>{navLinks}</NavigationList>
      </NavEl>
      <Actions>
        <LoginControl />
      </Actions>
    </Wrapper>
  )
}
