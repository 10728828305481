import { internalApi } from '@/api/axios.ts'

export type CalculatorResponse = {
  yearlyProfits: {
    total: number
    electricBillSavings: number
    gpuRent: number
    blockchain: number
    storageRent: number
    sellingEnergy: number
  }
}

export type CalculatorParamsSchema = {
  address: string
  monthlyBill: string
  ratePerKw: number
  solar: boolean
  battery: {
    capacity: number
    batteriesNumber: number
  }
  gpu: {
    modelName: string
    cardsNumber: number
    rentHourlyRate: number
    rentProbability: number
  }
  profitSources: {
    electricBillSavings: boolean
    gpuRent: boolean
    storageRent: boolean
    sellingEnergy: boolean
  }
}

export const computeCalculator = async (calculatorForm: CalculatorParamsSchema) => {
  return internalApi.post<CalculatorResponse>('forecaster/calculator/forecast', calculatorForm)
}
