import Joi from 'joi'

export interface ValidationError {
  [key: string]: string
}

export const calculatorSchema = Joi.object({
  address: Joi.string().required().messages({
    'string.empty': 'The address is required',
    'any.required': 'The address is required',
  }),
  monthlyBill: Joi.string().required().messages({
    'string.empty': 'The average electric monthly bill is required',
    'any.required': 'The average electric monthly bill is required',
  }),
})
