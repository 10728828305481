export const faqData = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    // Solar Questions
    ...['What is "Current needs" option for solar component', 'What is "Future needs" option for solar component'].map(
      (question) => ({
        '@type': 'Question',
        name: question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: "Click the 'Learn more' button for further information.",
        },
      })
    ),
    // Ava Questions
    ...[
      'What is AVA Light, and what features does it include',
      'Who is AVA Light designed for',
      'What is the cost of AVA Light',
      'Does AVA Light support GPU Compute capabilities',
      'What are the installation requirements for AVA Light',
      'What is AVA Pro, and what additional features does it offer over AVA Light',
      'Who is the ideal user of AVA Pro',
      'What is the cost of AVA Pro',
      'Does AVA Pro offer advanced GPU Compute capabilities',
      'What are the technical specifications of AVA Pro',
      'What is AVA Max, and what premium features are included',
      'Who is AVA Max designed for',
      'What is the cost of AVA Max',
      'What are the advanced GPU Compute capabilities of AVA Max',
      'What are the installation and maintenance requirements for AVA Max',
    ].map((question) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: "Click the 'Learn more' button for further information.",
      },
    })),
    // Battery Questions
    ...[
      'What are the benefits of adding a battery to my solar system',
      'How does a solar battery storage system work',
      'What types of batteries are available for solar systems',
      'How long do solar batteries last',
      'What is the capacity of a typical home solar battery',
    ].map((question) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: "Click the 'Learn more' button for further information.",
      },
    })),
  ],
}

export const productData = {
  '@context': 'https://schema.org',
  '@type': 'Product',
  name: 'Energy Solutions',
  description:
    'A range of energy products including Solar Panels, Ava, and Battery Storage to optimize energy production and usage.',
  brand: {
    '@type': 'Brand',
    name: 'GreenLightGreed',
  },
  offers: [
    // Solar Product
    {
      '@type': 'Offer',
      name: 'Solar Panels',
      description: 'Your recommended solar setup to cover current and future needs.',
      priceCurrency: 'USD',
      price: 11999,
      url: window.location.href,
    },
    // Ava Product
    {
      '@type': 'Offer',
      name: 'AVA',
      description: 'Maximize energy savings and profits from your surplus power.',
      priceCurrency: 'USD',
      price: 7999,
      image: '/assets/images/packagePreview/components/Ava.webp',
      url: window.location.href,
    },
    // Battery Product
    {
      '@type': 'Offer',
      name: 'Battery Storage',
      description:
        'Keep home essentials running for up to 8 hours during outages, and use your extra energy to power Ava for profits.',
      priceCurrency: 'USD',
      price: 9999,
      image: '/assets/images/packagePreview/components/battery.webp',
      url: window.location.href,
    },
  ],
}
