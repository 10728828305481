import { StyledInput } from '@/uiComponents/CommonComponents'
import styled from 'styled-components'

export const Container = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  border-radius: var(--radius-4xl);
  margin-bottom: 50px;
  background-color: var(--gray-light-mode-25);
  gap: 32px;
`

export const OptionTitle = styled.span`
  color: var(--base-black);
  font-size: var(--font-display-xs);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1.33;
  text-align: left;
`

export const CalculatorInput = styled(StyledInput)`
  border: none;
  background-color: var(--base-white);
  outline: solid 1px var(--gray-light-mode-400);
`
