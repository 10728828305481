import styled, { css } from 'styled-components'

export const FormContainer = styled.form`
  display: flex;
  max-width: 791px;
  flex-direction: column;
`

export const FormHeadingTitle = styled.h1`
  margin-bottom: 20px;
  font-size: var(--font-display-xl);

  @media (max-width: 768px) {
    font-size: var(--font-display-lg);
  }
`

export const FormSecondaryTitle = styled.h2`
  max-width: 596px;
  margin-bottom: 32px;
  font-size: var(--font-display-xs);
  font-weight: var(--font-weight-normal);
`

export const TertiaryTitle = styled.h4`
  margin-bottom: 17px;
  color: var(--gray-light-mode-700);
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-normal);
  line-height: 1.5rem;
`

export const FormLabel = styled.label`
  left: 16px;
  color: var(--gray-light-mode-900);
  font-size: var(--font-text-xl);
  line-height: 2rem;
  pointer-events: none;
  transition: all 0.1s ease-out;
`
export const RequiredIndicator = styled.span`
  color: var(--gray-light-mode-400);
  font-size: var(--font-text-xl);
  pointer-events: none;
  transition: all 0.02s ease-out;
  visibility: visible;
`

export const WrappContent = styled.div`
  position: absolute;
  top: 2px;
  left: 10px;
  display: flex;
  height: 65px;
  align-items: center;
  padding: 10px;
  gap: 24px;
  pointer-events: none;
  transition: all 0.1s ease-out;
`

export const Error = styled.p`
  margin-top: 6px;
  color: var(--error-600);
  font-size: var(--font-text-sm);
  line-height: 1.25rem;
`

const focusedStyle = css`
  top: -17px;

  > label {
    color: var(--gray-light-mode-500);
    font-size: var(--font-text-sm);
    line-height: 1.25rem;
  }

  > span {
    visibility: hidden;
  }
`

const hoverStyle = css`
  > span {
    color: #f670c7;
  }

  > input,
  > textarea {
    border-color: var(--hover-border-color);
  }
`

export const FormControlContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  &:hover > div {
    ${hoverStyle}
  }

  &.filled > div {
    ${focusedStyle}
  }

  &.error > input,
  &.error > textarea {
    border-color: var(--error-300);

    &:focus {
      box-shadow: var(--inner-shadow-error);
    }
  }

  &:focus-within > div {
    ${focusedStyle}
  }
`
