import styled, { css } from 'styled-components'
import ArrowRight from '@icons/arrow-right.svg?react'
import ArrowUp from '@icons/arrow-narrow.svg?react'
import Dot from '@icons/dot.svg?react'
import { buttonHeroTextSizes, buttonTextSizes } from './ButtonTextSizes'

import { ButtonTheme, ButtonSize, ButtonType } from './types'
import { StyledButton } from './StyledButton'
import { StyledArrowSizes } from './IconPathStyles'
import { CSSProperties, ReactNode } from 'react'

type HeroButtonSize = 'lg' | 'xl' | '2xl'

type ButtonTextSize = {
  size: ButtonSize
  theme: 'default' | 'dotLeading'
}

type TButtonDefaultHeroStyle = {
  size: HeroButtonSize
}

export interface HeroButtonProps {
  size: HeroButtonSize
  text: string
  type?: 'submit' | 'reset' | 'button'
  onClick?: () => void
}

interface ButtonProps {
  $buttonType: ButtonType
  size: ButtonSize
  disabled?: boolean
  theme: ButtonTheme
  text?: string
  type?: 'submit' | 'reset' | 'button'
  icon?: ReactNode
  onClick?: () => void
  block?: boolean
  style?: CSSProperties
}

/* Default Hero Button */
const buttonDefaultHeroSizeStyles = {
  lg: css`
    padding: 8px 8px 8px 20px;
    gap: 8px;
  `,
  xl: css`
    padding: 8px 8px 8px 24px;
    gap: 10px;
  `,
  '2xl': css`
    padding: 8px 8px 8px 28px;
    gap: 12px;
  `,
}

const StyledDefaultHeroButton = styled.button<TButtonDefaultHeroStyle>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
  background-color: var(--base-black);
  color: var(--base-white);

  &:hover {
    background-color: var(--gray-modern-700);
    color: #fdc3b3;
    transition:
      background-color 0.3s,
      color 0.3s;
  }

  ${({ size }) => buttonDefaultHeroSizeStyles[size]}
`

/* Hero Arrow Icon */
const StyledArrowContainer = styled.div<TButtonDefaultHeroStyle>`
  border-radius: 9999px;
  background-color: var(--base-white);
  ${({ size }) => StyledArrowSizes[size]}
`

const ButtonText = styled.span<ButtonTextSize>`
  font-weight: var(--font-weight-semi-bold);
  text-align: left;
  ${({ size, theme }: ButtonTextSize) => buttonTextSizes[theme][size]}
`

const ButtonHeroText = styled.span<TButtonDefaultHeroStyle>`
  font-weight: var(--font-weight-semi-bold);
  text-align: left;
  ${({ size }) => buttonHeroTextSizes[size]}
`

export function Button({
  disabled,
  size,
  $buttonType,
  text,
  theme,
  type,
  onClick,
  icon,
  block,
  style,
}: Readonly<ButtonProps>) {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      size={size}
      $buttonType={$buttonType}
      theme={theme}
      type={type}
      $block={block}
      style={style}
    >
      <ButtonThemeComponent theme={theme} text={text} size={size} icon={icon} />
    </StyledButton>
  )
}

export function HeroButton({ size, text, type, onClick }: Readonly<HeroButtonProps>) {
  return (
    <StyledDefaultHeroButton size={size} type={type} onClick={onClick}>
      <ButtonHeroText size={size}>{text}</ButtonHeroText>
      <StyledArrowContainer size={size}>
        <ArrowRight />
      </StyledArrowContainer>
    </StyledDefaultHeroButton>
  )
}

interface ButtonThemeProps {
  theme: ButtonTheme
  text?: string
  size: ButtonSize
  icon?: ReactNode
}

function ButtonThemeComponent({ size, text, theme, icon }: Readonly<ButtonThemeProps>) {
  switch (theme) {
    case 'default':
      return (
        <>
          <ButtonText size={size} theme={theme}>
            {text}
          </ButtonText>
          {icon && icon}
        </>
      )
    case 'dotLeading':
      return (
        <>
          <Dot />
          <ButtonText size={size} theme={theme}>
            {text}
          </ButtonText>
        </>
      )
    case 'onlyIcon':
      return <ArrowUp />
    default:
      return <></>
  }
}
