import styled from 'styled-components'

const ToggleContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 44px;
`

const ToggleInput = styled.input<{ $disabled?: boolean }>`
  width: 0;
  height: 0;
  opacity: 0;

  &:checked + span {
    background-color: ${(props) => (props.$disabled ? 'var(--gray-light-mode-300)' : '#506de7')};
  }

  &:checked + span::before {
    transform: translateX(20px);
  }
`

const Slider = styled.span`
  position: absolute;
  border-radius: 34px;
  background-color: var(--gray-light-mode-100);
  cursor: pointer;
  inset: 0;
  transition: 0.5s;

  &::before {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: var(--radius-full);
    background-color: var(--base-white);
    content: '';
    transition: 0.4s;
  }
`

const ToggleLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
`

type ToggleSwitchProps = {
  isChecked: boolean
  onChange: () => void
  disabled?: boolean
}

const ToggleSwitch = ({ isChecked, onChange, disabled }: ToggleSwitchProps) => {
  return (
    <ToggleContainer>
      <ToggleLabel>
        <ToggleInput type="checkbox" checked={isChecked} onChange={onChange} $disabled={disabled} />
        <Slider />
      </ToggleLabel>
    </ToggleContainer>
  )
}

export default ToggleSwitch
