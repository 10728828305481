import { css } from 'styled-components'

/* Default Size*/
export const buttonDefaultSizes = {
  sm: css`
    padding: 10px 14px;
    gap: 2px;
  `,
  md: css`
    padding: 14px 16px;
    gap: 4px;
  `,
  lg: css`
    padding: 16px 18px;
    gap: 6px;
  `,
  xl: css`
    padding: 18px 31px;
    gap: 8px;
  `,
  '2xl': css`
    padding: 22px 20px;
    gap: 10px;

    > svg {
      width: 24px;
      height: 24px;
    }
  `,
}

/* Dot Leading Size */
export const buttonDotLeadingSizes = {
  sm: css`
    padding: 10px 12px;
    gap: 8px;
  `,
  md: css`
    padding: 14px;
    gap: 8px;

    > svg {
      width: 10px;
      height: 10px;
    }
  `,
  lg: css`
    padding: 16px;
    gap: 8px;

    > svg {
      width: 10px;
      height: 10px;
    }
  `,
  xl: css`
    padding: 20px 18px;
    gap: 8px;

    > svg {
      width: 10px;
      height: 10px;
    }
  `,
  '2xl': css`
    padding: 22px;
    gap: 12px;

    > svg {
      width: 10px;
      height: 10px;
    }
  `,
}

/* Only Icon Size */
export const buttonOnlyIconSizes = {
  sm: css`
    padding: 12px;
    gap: 8px;

    > svg {
      width: 16px;
      height: 16px;
    }
  `,
  md: css`
    padding: 14px;
    gap: 8px;

    > svg {
      width: 20px;
      height: 20px;
    }
  `,
  lg: css`
    padding: 16px;
    gap: 8px;

    > svg {
      width: 24px;
      height: 24px;
    }
  `,
  xl: css`
    padding: 18px;
    gap: 8px;

    > svg {
      width: 28px;
      height: 28px;
    }
  `,
  '2xl': css`
    padding: 20px;
    gap: 8px;

    > svg {
      width: 32px;
      height: 32px;
    }
  `,
}
