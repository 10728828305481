import { createContext } from 'react'
import { Profile } from './profile'

export type ProfileContextType = {
  profile?: Profile
  isLoading: boolean
  refreshProfile: () => Promise<void>
  setToken: React.Dispatch<React.SetStateAction<string>>
  token: string
}

export const ProfileContext = createContext<ProfileContextType | undefined>(undefined)
