import { ChangeEvent } from 'react'
import styled from 'styled-components'
import {
  FormLabel,
  RequiredIndicator,
  WrappContent,
  Error,
  FormControlContainer,
} from '../installationRequest/CommonForm'

export interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  errorMessage?: string
}

const StyledInput = styled.input`
  width: 100%;
  height: 72px;
  padding: 30px 42px 10px 14px;
  border: 1px solid var(--primary-border-color);
  border-radius: 8px;
  font-size: var(--font-text-xl);
  transition: border-color 0.1s ease-in-out;

  &:focus {
    box-shadow: var(--inner-shadow);
  }
`

export const FormInput = ({ label, required, errorMessage, ...inputProps }: FormInputProps) => {
  const value = inputProps.value !== undefined ? (inputProps.value as string) : ''

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputProps.onChange) {
      inputProps.onChange(e)
    }
  }

  return (
    <FormControlContainer className={`${value.trim().length > 0 ? 'filled' : ''} ${errorMessage ? 'error' : ''}`}>
      <StyledInput onChange={handleInputChange} {...inputProps} value={value} maxLength={256} required={required} />
      <WrappContent>
        <FormLabel>{label}</FormLabel>
        {required && <RequiredIndicator>[required]</RequiredIndicator>}
      </WrappContent>
      {errorMessage && <Error>{errorMessage}</Error>}
    </FormControlContainer>
  )
}
