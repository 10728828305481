import { Libraries, useJsApiLoader } from '@react-google-maps/api'
import { useState, useEffect } from 'react'
const libraries: Libraries = ['places', 'geometry']

export function useGoogleMapsLoader() {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [loadError, setLoadError] = useState<Error | undefined>()

  const { isLoaded: jsApiLoaded, loadError: jsApiLoadError } = useJsApiLoader({
    id: 'google-api-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    libraries,
  })

  useEffect(() => {
    setIsLoaded(jsApiLoaded)
    setLoadError(jsApiLoadError)
  }, [jsApiLoaded, jsApiLoadError])

  return { isLoaded, loadError }
}
