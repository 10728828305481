import styled, { css } from 'styled-components'
import { ButtonSize, ButtonTheme, ButtonType } from './types'
import { buttonTypeStyles } from './ButtonStyles'
import { buttonDefaultSizes, buttonDotLeadingSizes, buttonOnlyIconSizes } from './ButtonThemesSizes'
import { IconPathStyles } from './IconPathStyles'

type ButtonStyle = {
  $buttonType: ButtonType
  size: ButtonSize
  theme: ButtonTheme
  $block?: boolean
}
export const StyledButton = styled.button<ButtonStyle>`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  ${({ size, theme, $buttonType, $block }: ButtonStyle) => css`
    ${getThemeSize(theme, size)}
    ${buttonTypeStyles[$buttonType]}
    ${IconPathStyles[theme][$buttonType]}
    ${$block && 'width: 100%;'}
  `}
`
const getThemeSize = (theme: ButtonTheme, size: ButtonSize) => {
  switch (theme) {
    case 'default':
      return buttonDefaultSizes[size]
    case 'dotLeading':
      return buttonDotLeadingSizes[size]
    case 'onlyIcon':
      return buttonOnlyIconSizes[size]

    default:
      break
  }
}
