import React from 'react'
import styled from 'styled-components'
import { Button } from './button/Button'
import { NavLink } from 'react-router-dom'

const ExternalLink = styled(NavLink)`
  padding: 0.5rem;
  color: var(--primary-color);
  font-size: var(--font-display-sm);
  text-decoration: none;

  &:hover {
    color: var(--accent-color);
    text-decoration: underline;
  }
`

const ContactLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 1rem;
`

const Container = styled.div`
  display: flex;
  height: 50vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: var(--radius-sm);
  margin: 90px 0;
  background-color: var(--gray-light-mode-25);
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  text-align: center;
`

const Title = styled.h1`
  margin: 0;
  color: var(--base-black);
  font-size: var(--font-display-3xl);
`

const Message = styled.p`
  margin: 20px 0;
  color: var(--gray-light-mode-900);
  font-size: var(--font-display-xs);
`

const ErrorView: React.FC = () => {
  return (
    <Container>
      <Title>Something Went Wrong</Title>
      <Message>We encountered an error. You can try reloading the page or reach out to us for help.</Message>
      <Button
        onClick={() => {
          window.location.reload()
        }}
        $buttonType="secondary"
        size="lg"
        theme="default"
        text="Reload Page"
        type="button"
      />
      <ContactLinks>
        <ExternalLink to="https://www.linkedin.com/company/avaenergy/" target="_blank">
          LinkedIn
        </ExternalLink>
        <ExternalLink to="https://x.com/ava_energy_ai" target="_blank">
          X
        </ExternalLink>
      </ContactLinks>
    </Container>
  )
}

export default ErrorView
