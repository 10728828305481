import styled from 'styled-components'
import avaImg from '@/assets/images/calculator/ava.webp'
import avaImgx2 from '@/assets/images/calculator/ava@2x.webp'
import avaImgx3 from '@/assets/images/calculator/ava@3x.webp'
import Select from './Select'
import { Container } from './CommonCalculator'
import { Option } from '@/uiComponents/CommonComponents'
import InputNumber from '@/uiComponents/InputNumber'
import { GPUSettings } from './Calculator'
import { useCallback } from 'react'

const CardContainer = styled.article`
  display: flex;
  width: 100%;
  max-width: 855px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 12px;
  border: solid 4px var(--gray-modern-100);
  border-radius: 32px;
  padding-inline: 10px;

  @media (min-width: 464px) {
    padding: 32px 24px;
  }
`
const ImageContainer = styled.div`
  width: 206px;
  height: 270px;
  margin-top: 40px;

  @media (min-width: 393px) {
    width: 266px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: var(--radius-4xl);
  object-fit: cover;
`

const AttributesContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
`

const AttributeLabel = styled.span`
  display: flex;
  color: var(--gray-modern-900);
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-bold);
  gap: 10px;
  text-align: left;

  @media (min-width: 744px) and (max-width: 1248px) {
    font-size: var(--font-text-sm);
  }
`

const AttributeName = styled.p`
  color: var(--gray-modern-900);
  font-size: var(--font-text-md);
  font-weight: var(--font-weight-medium);
  text-align: left;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 50px;

  @media (min-width: 712px) {
    flex-direction: row;
    gap: 25px;
  }
`

const CardInfoContainer = styled(Container)`
  gap: 32px;
`

const CardTitle = styled.span`
  width: '100%';
  padding-inline: 20px;
  text-align: left;
`

const GPUOptionsContainer = styled.div`
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  @media (max-width: 984px) {
    grid-template-columns: 1fr;
  }
`

type AvaNodeCardProps = {
  gpuModels: Pick<GPUSettings, 'modelName' | 'rentProbability' | 'rentHourlyRate'>[]
  gpuSettings: GPUSettings
  setGpuSettings: React.Dispatch<React.SetStateAction<GPUSettings>>
}

const AvaNodeCard = ({ gpuModels, gpuSettings, setGpuSettings }: AvaNodeCardProps) => {
  const imageSrcSet = `${avaImg} 1x, ${avaImgx2} 2x, ${avaImgx3} 3x`

  const onHandleGPUCount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setGpuSettings((prev) => ({
        ...prev,
        cardsNumber: parseFloat(value),
      }))
    },
    [setGpuSettings]
  )

  const onHandleRentProbabilityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setGpuSettings((prev) => ({
        ...prev,
        rentProbability: parseFloat(value),
      }))
    },
    [setGpuSettings]
  )

  const onHandleRentCostChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setGpuSettings((prev) => ({
        ...prev,
        rentHourlyRate: parseFloat(value),
      }))
    },
    [setGpuSettings]
  )

  const handleGPUChange = useCallback(
    (selectedGpu: Pick<GPUSettings, 'modelName' | 'rentProbability' | 'rentHourlyRate'>) => {
      setGpuSettings((prevSettings) => ({
        ...prevSettings,
        modelName: selectedGpu.modelName,
        rentProbability: selectedGpu.rentProbability * 100,
        rentHourlyRate: selectedGpu.rentHourlyRate,
      }))
    },
    [setGpuSettings]
  )

  return (
    <CardContainer>
      <CardTitle>Ava Node</CardTitle>
      <Wrapper>
        <ImageContainer>
          <Image src={avaImg} srcSet={imageSrcSet} alt="" />
        </ImageContainer>
        <CardInfoContainer>
          <AttributesContainer>
            <Container>
              <AttributeLabel>CPU</AttributeLabel>
              <AttributeName>AMD Ryzen Threadripper</AttributeName>
            </Container>
            <Container>
              <AttributeLabel>RAM</AttributeLabel>
              <AttributeName>16 Gb</AttributeName>
            </Container>
            <Container>
              <AttributeLabel>SSD Hard Drive</AttributeLabel>
              <AttributeName>1 TB</AttributeName>
            </Container>
            <Container>
              <AttributeLabel>Motherboard</AttributeLabel>
              <AttributeName>Gigabyte</AttributeName>
            </Container>
          </AttributesContainer>
          <GPUOptionsContainer>
            <Select
              title="GPU Model"
              onChange={(gpuModelName) => {
                const selectedGpu = gpuModels.find((gpu) => gpu.modelName === gpuModelName)
                if (selectedGpu) {
                  handleGPUChange(selectedGpu)
                }
              }}
            >
              {gpuModels.map((gpu: Pick<GPUSettings, 'modelName'>) => (
                <Option key={gpu.modelName} value={gpu.modelName}>
                  {gpu.modelName}
                </Option>
              ))}
            </Select>
            <Container>
              <AttributeName>Number of GPU Cards</AttributeName>
              <InputNumber value={gpuSettings.cardsNumber} onChange={onHandleGPUCount} required />
            </Container>
            <Container>
              <AttributeName>Monthly Load</AttributeName>
              <InputNumber
                value={gpuSettings.rentProbability}
                onChange={onHandleRentProbabilityChange}
                symbol="%"
                symbolPosition={75}
                required
              />
            </Container>
            <Container>
              <AttributeName>Hourly Rate</AttributeName>
              <InputNumber
                value={gpuSettings.rentHourlyRate}
                onChange={onHandleRentCostChange}
                symbol="$"
                symbolPosition={25}
                required
              />
            </Container>
          </GPUOptionsContainer>
        </CardInfoContainer>
      </Wrapper>
    </CardContainer>
  )
}

export default AvaNodeCard
