import ax, { AxiosError, InternalAxiosRequestConfig } from 'axios'
import * as Sentry from '@sentry/react'

const onFulfilled = (config: InternalAxiosRequestConfig) => {
  config.headers['X-Auth-Token'] = localStorage.getItem('userId')

  return config
}

const onRejected = (error: AxiosError) => {
  // Capture detailed error information
  const url = error.config?.url ?? ''
  if (error.response) {
    // Server responded with a status other than 200 range
    Sentry.captureException(new Error(`HTTP Error: ${url}`), {
      extra: {
        url: error.config?.url,
        method: error.config?.method,
        data: error.config?.data,
        response: error.response.data,
        status: error.response.status,
        message: error.message,
      },
    })
  } else if (error.request) {
    // Request was made but no response received
    Sentry.captureException(new Error(`HTTP Error: ${url}`), {
      extra: {
        url: error.config?.url,
        method: error.config?.method,
        data: error.config?.data,
        message: error.message,
      },
    })
  } else {
    // Something happened in setting up the request
    Sentry.captureException(new Error(`Axios error: ${error.message}`), {
      extra: {
        config: error.config,
      },
    })
  }
  return Promise.reject(error)
}

const internalApi = ax.create({
  baseURL: String(import.meta.env.VITE_APP_API_URL),
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  transitional: {
    clarifyTimeoutError: true,
  },
})

internalApi.interceptors.request.use(
  (config) => onFulfilled(config),
  (error: AxiosError) => onRejected(error)
)

internalApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => onRejected(error)
)

export { internalApi }
