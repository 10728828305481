import { FormDataBusiness, FormDataHome, Installation } from './installation.types'

export function prepareHomeInstallationRequest(formData: FormDataHome): Installation {
  return {
    requestType: 'home',
    contactInformation: {
      fullName: formData.fullName,
      email: formData.email,
      phone: formData.phone, // optional for home
    },
  }
}

export function prepareBusinessInstallationRequest(formData: FormDataBusiness): Installation {
  return {
    requestType: 'business',
    desiredSystemComponents: {
      solar: formData.solar,
      wind: formData.wind,
      battery: formData.battery,
    },
    contactInformation: {
      fullName: formData.fullName,
      email: formData.email,
      phone: formData.phone,
    },
    businessInformation: {
      title: formData.title,
      businessName: formData.businessName,
      businessAddress: formData.businessAddress,
      industry: formData.industry,
    },
  }
}
