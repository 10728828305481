import { Installation } from '@/installationRequest/installation.types.ts'
import { request } from '../api/internalApi.ts'

export const requestInstallation = async (content: Installation) => {
  const threadId = localStorage.getItem('thread_id')
  const requestBody = {
    request: content,
    ...(threadId ? { threadId: threadId } : {}),
  }
  return request('post', `/installation-requests`, requestBody)
}
