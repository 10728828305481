import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import UserIcon from '@/assets/icons/user.svg?react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useMediaQuery } from 'react-responsive'
import { PathConstants } from '@/pathConstant'
import { Button } from '@/uiComponents/button/Button.tsx'

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()
  const isMobileWidth = useMediaQuery({ query: '(max-width: 768px)' })

  const handleLogin = useCallback(() => {
    void loginWithRedirect({
      appState: { returnToPath: window.location.pathname + window.location.search },
    })
  }, [loginWithRedirect])

  return (
    <Button
      size={isMobileWidth ? 'lg' : 'sm'}
      $buttonType="primary"
      text="Sign in"
      theme="default"
      onClick={handleLogin}
      block={isMobileWidth}
    />
  )
}

const LogoutButton = () => {
  const { logout } = useAuth0()
  const isMobileWidth = useMediaQuery({ query: '(max-width: 768px)' })

  const handleLogout = useCallback(() => {
    sessionStorage.setItem('auth.logout.returnToPath', window.location.pathname + window.location.search)
    void logout({
      logoutParams: {
        returnTo: `${window.location.origin}/${PathConstants.POST_LOGOUT}`,
      },
    })
  }, [logout])

  return (
    <Button
      size={isMobileWidth ? 'lg' : 'sm'}
      $buttonType="secondary"
      text="Sign out"
      theme="default"
      onClick={handleLogout}
      block
    />
  )
}

type TUserIconButtonProps = {
  $isShowUserData: boolean
}

const UserIconButton = styled.button<TUserIconButtonProps>`
  position: relative;
  z-index: 1;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: solid 1px rgb(0 0 0 / 8%);
  border-radius: 9999px;
  background-color: var(--gray-light-mode-100);
  outline: ${({ $isShowUserData }) => ($isShowUserData ? '4px solid #f3e8ff' : 'none')};
  transition:
    border 0.2s ease-in-out,
    outline 0.1s ease-in-out;

  &:is(:hover, :focus) {
    border-color: ${({ $isShowUserData }) => ($isShowUserData ? 'var(--accent-1-300)' : 'var(--gray-light-mode-400)')};
  }

  &:is(:active) {
    border-color: var(--accent-1-300);
  }
`

const UserDataContainer = styled.div`
  position: relative;
`

const UserData = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  width: 240px;
  flex-direction: column;
  border: solid 1px var(--gray-light-mode-200);
  border-radius: 8px;
  background-color: var(--base-white);
  box-shadow:
    0 4px 6px -2px rgb(16 24 40 / 3%),
    0 12px 16px -4px rgb(16 24 40 / 8%);
  gap: 4px;
`

const UserDataItem = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 12px 16px;
  border-bottom: solid 1px var(--gray-light-mode-200);
  text-overflow: ellipsis;

  &:last-child {
    border-bottom: none;
  }
`

const StyledUserIcon = styled(UserIcon)`
  pointer-events: none;
  stroke: var(--gray-light-mode-500);
`

const DropDownUserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const DropDownIconContainer = styled.div`
  display: flex;
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: solid 1px rgb(0 0 0 / 8%);
  border-radius: 9999px;
  background-color: var(--gray-light-mode-100);
`

const DropDownUserName = styled.span`
  overflow: hidden;
  max-width: 164px;
  color: var(--gray-light-mode-700);
  font-size: 14px;
  font-weight: var(--font-weight-semi-bold);
  text-overflow: ellipsis;
`

const DropDownUserEmail = styled.span`
  overflow: hidden;
  max-width: 164px;
  color: var(--gray-light-mode-600);
  font-size: var(--font-text-xs);
  font-weight: var(--font-weight-normal);
  text-overflow: ellipsis;
`

const DropDownUserData = styled.div`
  display: grid;
`

export const LoginControl = () => {
  const isMobileWidth = useMediaQuery({ query: '(max-width: 768px)' })
  const userDataContainerEl = useDetectClickOutside({
    onTriggered: () => {
      if (isShowUserData) {
        setIsShowUserData(false)
      }
    },
  })
  const [isShowUserData, setIsShowUserData] = useState<boolean>(false)
  const handleUserDataClick = useCallback(() => {
    setIsShowUserData(!isShowUserData)
  }, [isShowUserData])

  const userIconButtonEl = useRef<HTMLButtonElement>(null)

  const { user, isLoading, isAuthenticated } = useAuth0()

  if (isLoading) {
    return <></>
  }
  if (!isAuthenticated || !user) {
    return <LoginButton />
  }

  const username = user.preferred_username || user.nickname || 'no username'

  return (
    <>
      {isMobileWidth ? (
        <LogoutButton />
      ) : (
        <UserDataContainer ref={userDataContainerEl}>
          <UserIconButton
            ref={userIconButtonEl}
            type={'button'}
            onClick={handleUserDataClick}
            $isShowUserData={isShowUserData}
          >
            <StyledUserIcon width={20} height={20} />
          </UserIconButton>
          {isShowUserData && (
            <UserData>
              <UserDataItem>
                <DropDownUserInfo>
                  <DropDownIconContainer>
                    <StyledUserIcon width={16} height={16} />
                  </DropDownIconContainer>
                  <DropDownUserData>
                    <DropDownUserName>{username}</DropDownUserName>
                    <DropDownUserEmail>{user.email && user.email}</DropDownUserEmail>
                  </DropDownUserData>
                </DropDownUserInfo>
              </UserDataItem>
              <UserDataItem>
                <LogoutButton />
              </UserDataItem>
            </UserData>
          )}
        </UserDataContainer>
      )}
    </>
  )
}
