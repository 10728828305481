import { useContext } from 'react'
import { ProfileContext, ProfileContextType } from './ProfileContext'

export const useProfile = (): ProfileContextType => {
  const context = useContext(ProfileContext)
  //TODO: Remove
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider')
  }
  return context
}
