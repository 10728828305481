import styled from 'styled-components'

export const Title = styled.span`
  color: var(--base-black);
  font-size: var(--font-display-md);
  font-weight: var(--font-weight-medium);
`

export const StyledSubtitle = styled.h4`
  margin-bottom: 12px;
  font-weight: var(--font-weight-medium);
`

export const FinalPriceValue = styled.p`
  color: var(--gray-light-mode-900);
  font-size: var(--font-display-2lg);
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: -0.96px;
  line-height: 1.25;
`

export const ProductPrice = styled.p`
  color: var(--gray-light-mode-900);
  font-size: var(--font-text-lg);
  font-weight: var(--font-weight-semi-bold);
  line-height: 1.56;
`

export const IncentivesPrice = styled(ProductPrice)`
  font-size: var(--font-display-2xs);
`
export const Option = styled.option`
  color: var(--gray-modern-900);
  font-size: var(--font-text-lg);
  font-weight: var(--font-weight-medium);
  line-height: 1.56;
  text-align: left;
`

export const StyledInput = styled.input`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 25px;
  border: solid 0 var(--gray-light-mode-200);
  border-radius: 12px;
  background-color: var(--gray-light-mode-100);
  gap: 8px;

  &:focus {
    border: none;
    background-color: var(--base-white);
    box-shadow:
      0 2px 4px -2px rgb(16 24 40 / 6%),
      0 4px 8px -2px rgb(16 24 40 / 10%);
    outline: solid 1px var(--gray-light-mode-500);
  }
`
