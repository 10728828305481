import styled from 'styled-components'
import { Container } from './CommonCalculator'
import { StyledSubtitle } from '@/uiComponents/CommonComponents'
import ArrowIcon from '@icons/arrow-bottom.svg'
import React from 'react'

const Dropdown = styled.select`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 14px;
  border: solid 1px var(--gray-modern-300);
  border-radius: var(--radius-xl);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--base-white);
  background-image: url(${ArrowIcon});
  background-position: right 14px center;
  background-repeat: no-repeat;
  box-shadow: 0 1px 2px 0 #1018280d;
  cursor: pointer;
  gap: 8px;
`

const SelectTitle = styled(StyledSubtitle)`
  margin-bottom: unset;
`

type SelectProps = {
  title?: string
  children: React.ReactNode
  width?: string
  value?: string
  onChange?: (option: string) => void
}

const Select = ({ children, title, width, onChange, value }: SelectProps) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(event.target.value)
    }
  }

  return (
    <Container width={width}>
      {title && <SelectTitle>{title}</SelectTitle>}
      <Dropdown value={value} onChange={handleSelectChange}>
        {children}
      </Dropdown>
    </Container>
  )
}

export default Select
