import { css } from 'styled-components'

export const buttonHeroTextSizes = {
  lg: css`
    font-size: var(--font-text-md);
    line-height: 1.5;
  `,
  xl: css`
    font-size: var(--font-text-lg);
  `,
  '2xl': css`
    font-size: var(--font-text-lg);
  `,
}

export const buttonTextSizes = {
  default: {
    sm: css`
      font-size: var(--font-text-sm);
      line-height: 1.43;
    `,
    md: css`
      font-size: var(--font-text-sm);
      line-height: 1.43;
    `,
    lg: css`
      font-size: var(--font-text-md);
    `,
    xl: css`
      font-size: var(--font-text-lg);
      line-height: 1.56;
    `,
    '2xl': css`
      font-size: var(--font-text-lg);
      line-height: 1.56;
    `,
  },
  dotLeading: {
    sm: css`
      font-size: var(--font-text-sm);
      line-height: 1.43;
    `,
    md: css`
      font-size: var(--font-text-sm);
      line-height: 1.43;
    `,
    lg: css`
      font-size: var(--font-text-md);
      line-height: 1.5;
    `,
    xl: css`
      font-size: var(--font-text-md);
      line-height: 1.5;
    `,
    '2xl': css`
      font-size: var(--font-text-lg);
      line-height: 1.56;
    `,
  },
}
