import { createContext } from 'react'

export type InstallationRequestContextType = {
  proceedWithInstallationRequest: boolean
  setProceedWithInstallationRequest: React.Dispatch<React.SetStateAction<boolean>>
}

export const InstallationRequestContext = createContext<InstallationRequestContextType>({
  proceedWithInstallationRequest: false,
  setProceedWithInstallationRequest: () => {},
})
