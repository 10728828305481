import { css } from 'styled-components'

export const IconPathStyles = {
  default: {
    primary: css`
      svg path {
        stroke: var(--base-white);
      }

      &:hover {
        svg path {
          stroke: #fdc3b3;
          transition: stroke 0.3s;
        }
      }

      &:disabled {
        svg path {
          stroke: var(--gray-light-mode-400);
        }
      }
    `,
    secondary: css`
      svg path {
        stroke: var(--base-white);
      }

      &:disabled {
        svg path {
          stroke: var(--gray-light-mode-400);
        }
      }
    `,
  },
  dotLeading: {
    primary: css`
      &:hover {
        svg circle {
          fill: var(--base-white);
        }
      }

      &:focus {
        border: solid 1px #02b671;
        box-shadow: 0 0 0 4px rgba(var(--brand-primary-500) / 30%);
      }

      &:disabled {
        svg circle {
          fill: var(--gray-light-mode-300);
        }
      }
    `,
    secondary: css`
      svg circle {
        fill: var(--base-white);
      }

      &:disabled {
        svg circle {
          fill: var(--gray-light-mode-300);
        }
      }
    `,
  },
  onlyIcon: {
    primary: css`
      &:hover {
        svg path {
          stroke: var(--accent-2-100);
        }
      }

      &:focus {
        box-shadow: 0 0 0 4px rgba(var(--brand-primary-500) / 30%);
        outline: solid 1px #02b671;
      }

      &:disabled {
        svg path {
          stroke: var(--gray-light-mode-400);
        }
      }
    `,
    secondary: css`
      svg path {
        stroke: var(--base-white);
      }

      &:disabled {
        svg path {
          stroke: var(--gray-light-mode-400);
        }
      }
    `,
  },
}

export const StyledArrowSizes = {
  lg: css`
    width: 40px;
    height: 40px;
    padding: 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  `,

  xl: css`
    width: 48px;
    height: 48px;
    padding: 12px;

    svg {
      width: 24px;
      height: 24px;
    }
  `,

  '2xl': css`
    width: 56px;
    height: 56px;
    padding: 14px;

    svg {
      width: 28px;
      height: 28px;
    }
  `,
}
