import { css } from 'styled-components'

export const ResponsiveLayout = {
  queries: () => css`
    @media (min-width: 320px) {
      padding-inline: 20px;
    }

    @media (min-width: 464px) {
      padding-inline: 60px;
    }

    @media (min-width: 744px) {
      padding-inline: 80px;
    }
  `,
}

export const ResponsiveSalesAssistantLayout = {
  queries: () => css`
    @media (min-width: 744px) {
      padding-inline: 80px;
    }
  `,
}
