import { createContext } from 'react'

export type OptimizedPackageContextType = {
  packageLoading: boolean
  setPackageLoading: React.Dispatch<React.SetStateAction<boolean>>
  setLearnMoreQuestions: React.Dispatch<React.SetStateAction<string[] | undefined>>
  learnMoreQuestions?: string[]
}

export const OptimizedPackageContext = createContext<OptimizedPackageContextType>({
  packageLoading: true,
  setPackageLoading: () => {},
  setLearnMoreQuestions: () => {},
})
