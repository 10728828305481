import styled from 'styled-components'
import { Container } from './CommonCalculator'
import { useCallback, useState } from 'react'
import ToggleSwitch from '@/uiComponents/ToggleSwitch'
import { StyledSubtitle } from '@/uiComponents/CommonComponents'

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  border: solid 1px var(--gray-modern-300);
  border-radius: var(--radius-xl);
  margin: 8px 0 0;
  background-color: var(--base-white);
  gap: 16px;
`
const Text = styled.span`
  color: var(--gray-modern-900);
  font-size: var(--font-text-lg);
  font-weight: var(--font-weight-medium);
  line-height: 1.56;
  text-align: left;
`

type ToggleOptionProps = {
  title?: string
  text: string
  onChange?: (value: boolean) => void
  disabled?: boolean
}

const ToggleOption = ({ text, title, onChange, disabled }: ToggleOptionProps) => {
  const [toggle, setToggle] = useState<boolean>(true)

  const handleToggle = useCallback(() => {
    if (onChange) {
      setToggle(!toggle)
      onChange(!toggle)
    }
  }, [onChange, toggle])

  return (
    <Container>
      {title && <StyledSubtitle>{title}</StyledSubtitle>}
      <Box>
        <ToggleSwitch isChecked={toggle} onChange={handleToggle} disabled={disabled} />
        <Text>{text}</Text>
      </Box>
    </Container>
  )
}

export default ToggleOption
