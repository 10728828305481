import { css } from 'styled-components'

export const buttonTypeStyles = {
  primary: css`
    border-radius: 46px;
    background-color: var(--base-black);
    color: var(--base-white);

    &:hover {
      background-color: var(--gray-modern-700);
      color: #fdc3b3;
      transition:
        background-color 0.3s,
        color 0.3s;
    }

    &:disabled {
      border: none;
      background-color: #eaecf5;
      background-image: none;
      box-shadow: unset;
      color: var(--gray-light-mode-400);
      cursor: default;
      outline: none;
    }
  `,
  secondary: css`
    border-radius: 8px;
    background-image: linear-gradient(to bottom, rgb(102 112 133 / 80%), rgb(102 112 133 / 0%)),
      linear-gradient(to bottom, var(--gray-light-mode-600), var(--gray-light-mode-600));
    box-shadow:
      0 1px 0 0 rgb(102 112 133 / 15%),
      0 3px 4px -2px rgb(16 24 40 / 10%),
      0 4px 8px -2px rgb(16 24 40 / 10%),
      inset 0 1px 0 1px #8e99b1,
      inset 0 0 0 1px var(--gray-light-mode-600),
      inset 0 -1px 0 0 var(--gray-light-mode-800);
    color: var(--base-white);

    &:hover {
      border-radius: 8px;
      background-image: linear-gradient(to bottom, rgb(102 112 133 / 80%), rgb(102 112 133 / 0%)),
        linear-gradient(to bottom, var(--gray-light-mode-800), var(--gray-light-mode-800));
      box-shadow: var(--box-shadow-hover);
      color: var(--base-white);
      outline: solid 2px var(--gray-light-mode-300);
    }

    &:focus {
      border-radius: 8px;
      background-color: var(--gray-light-mode-600);
      color: var(--base-white);
    }

    &:disabled {
      border: none;
      border-radius: 8px;
      background-color: #eaecf5;
      background-image: none;
      box-shadow: none;
      color: var(--gray-light-mode-400);
      cursor: default;
      outline: none;
    }
  `,
}
