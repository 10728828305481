import { AxiosRequestConfig } from 'axios'
import { internalApi } from './axios.ts'

export type ApiMethod = 'get' | 'post' | 'put' | 'delete'

export type ApiError = {
  code: string
  response?: {
    data: string
    status: number
  }
}

export interface ApiPrivateError {
  text: string | undefined
  status: number | undefined
  axiosCode: string
}

export interface ApiPrivateResponse<T> {
  data: T | null
  error: ApiPrivateError | null
}

export const request = async <T>(
  method: ApiMethod,
  url: string,
  requestData?: unknown,
  ...args: AxiosRequestConfig[]
): Promise<ApiPrivateResponse<T>> => {
  try {
    const { data } = await internalApi[method]<T>(url, requestData, ...args)
    return { data, error: null }
  } catch (_error) {
    const { response, code } = _error as ApiError
    return {
      data: null,
      error: {
        text: response?.data,
        status: response?.status,
        axiosCode: code,
      },
    }
  }
}
